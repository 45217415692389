<template>
    <!-- To use this class the API to list class need return a object [{id: 1, name: 'some name'}] -->
    <v-container grid-list-md text-xs-center fluid mb-16>
        <slot v-bind="{ filtered: listWithFilter, items: items ? items : itemsTable }" />

        <v-layout wrap>
            <v-flex xs12 md6>
                <v-btn class="ma-2" color="success" dark @click="newItem()" v-if="!hideNew">
                    <v-icon dark left>add</v-icon>
                    {{ $t("Criar Novo") }}
                </v-btn>
            </v-flex>
            <v-flex xs12 md6> 
                <v-text-field :label="$t('Filtrar...')" v-model="filterName" clearable />
            </v-flex>
        </v-layout>

        <v-data-table :headers="headers" :items="listWithFilter" class="elevation-2" :hide-default-footer="hideDefaultFooter" :footer-props="footerProps" sort-by.sync="name"
            :show-expand="showExpand" :expanded.sync="expanded" item-key="id" :options.sync="options" :server-items-length="itemsTotal">
            <v-progress-linear progress color="blue" indeterminate />
            <template v-slot:item.enable="{ item }">
                <v-simple-checkbox v-model="item.enable" :disabled="!inlineToggle" @input="toggle(item)"></v-simple-checkbox>
            </template>
            <template v-slot:item.id="{ item }">
                {{ item.id ? `${item.id.substr(item.id.length - 7)}` : "" }}
            </template>

            <template v-slot:item.extra-column="{ item }">
                <slot name="extra-column" v-bind="item" />
            </template>

            <template v-slot:item.more-extra-column="{ item }">
                <slot name="more-extra-column" v-bind="item" />
            </template>

            <template v-slot:item.actions="{ item }">
                <slot name="more-btn" v-bind="item" />
                <v-btn class="ma-2" color="success" dark small @click="editItem(item)" v-if="!hideEdit">
                    <v-icon dark small>edit</v-icon>
                </v-btn>
                <v-btn class="ma-2" color="error" dark small @click="deleteItem(item)" v-if="!hideDelete">
                    <v-icon dark small>delete</v-icon>
                </v-btn>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
                <slot name="expanded" v-bind="{ headers, item }" />
            </template>

        </v-data-table>

        <slot name="footer"></slot>

        <v-layout row justify-center v-if="formclass">
            <v-dialog v-model="showForm" fullscreen persistent hide-overlay transition="dialog-bottom-transition" @keydown.esc="closeFormByUser">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="closeFormByUser">
                            <v-icon>close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ title }}</v-toolbar-title>
                    </v-toolbar>
                    <component v-bind:is="componentForm" :itemToEdit="itemToEdit" :lock="lockForm" @save="saveForm" @close="closeFormByUser"></component>
                </v-card>
            </v-dialog>
        </v-layout>

        <ConfirmDelete :endpoint="endpoint" :ID="deleteItemID" :show="showDeleteModal" :msg="deleteMessage" :msgItem="deleteMessageItem" @close="showDeleteModal = false"
            @onConfirm="deletedConfirm()" v-if="!hideDelete"></ConfirmDelete>
    </v-container>
</template>

<script>
import ConfirmDelete from "@/components/core/ConfirmDelete";

export default {
    components: { ConfirmDelete },

    props: {
        endpoint: {
            type: String,
            required: true,
        },
        formclass: {
            type: String,
        },
        items: {
            type: Array,
        },
        aditionalColumns: {
            type: [Object, Array, String],
            default: "",
        },
        hideIdColumn: {
            type: Boolean,
            default: false,
        },
        hideNew: {
            type: Boolean,
            default: false,
        },
        hideEdit: {
            type: Boolean,
            default: false,
        },
        hideDelete: {
            type: Boolean,
            default: false,
        },
        inlineToggle: {
            type: Boolean,
            default: false,
        },
        forceReloadOnChange: {
            type: Boolean,
            default: true,
        },
        keepOpenFormOnSave: {
            type: [Boolean, String],
            default: false,
        },
        updateTime: {
            type: Number,
            default: 0,
        },
        showExpand: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            itemsTotal: 0,
            options: {
                itemsPerPage: 10,
                page: 1,
                itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
            },
            footerProps: {
                itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
                showFirstLastPage: true,
                showCurrentPage: true,
                showItemsPerPage: true,
                showQtdSelected: true,
                showQtdOfItems: true,
            },
            expanded: [],
            timer: null,
            timerToSeach: null,
            filterName: null,
            hideDefaultFooter: false,
            headers: [
                { text: "#", value: "id", align: "left", sortable: false },
                { text: "", value: "actions", align: "right", sortable: false },
            ],

            itemsTable: [],

            showForm: false,
            lockForm: false,
            itemToEdit: null,

            showDeleteModal: false,
            deleteItemID: null,
            deleteMessage: "",
            deleteMessageItem: "",
        };
    },

    watch: {
        options: {
            handler() {
                this.update()
            },
            deep: true,
        },
        filterName: {
            handler() {
                if (this.timerToSeach) {
                    clearTimeout(this.timerToSeach);
                }
                this.timerToSeach = setTimeout(() => {
                    this.update()
                }, 1000);
            },
        },
    },

    computed: {
        componentForm() {
            return () => (this.formclass ? import(`@/pages/${this.formclass}`) : null);
        },

        title() {
            return this.itemToEdit == null ? this.$t("Cadastrando novo") : this.$t("Editando");
        },

        listWithFilter() {
            let ret = this.items ? this.items : this.itemsTable;
            // if (this.filterName) {
            //     let exp = new RegExp(
            //         this.filterName
            //             .trim()
            //             .normalize("NFD")
            //             .replace(/[\u0300-\u036f]/g, ""),
            //         "i"
            //     );
            //     ret = ret.filter(
            //         (item) =>
            //             typeof item === "object" &&
            //             item !== null &&
            //             exp.test(
            //                 JSON.stringify(Object.values(item))
            //                     .normalize("NFD")
            //                     .replace(/[\u0300-\u036f]/g, "")
            //             )
            //     );
            // }
            return ret;
        },
    },

    methods: {
        closeFormByUser() {
            this.closeFormScreen();
            if (this.keepOpenFormOnSave) {
                this.changeDataList();
            }
        },

        closeFormScreen() {
            this.itemToEdit = {};

            this.showForm = false;
            this.itemToEdit = null;
        },

        openFormScreen() {
            this.$nextTick(() => {
                this.showForm = true;
            });
        },

        editItem(item) {
            this.itemToEdit = {};
            this.$http
                .get(`${this.endpoint}/${item.id}`)
                .then((result) => {
                    this.itemToEdit = result;

                    this.openFormScreen();
                })
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao buscar dado para edição."));
                });
        },

        toggle(item) {
            this.$http.patch(`${this.endpoint}`, { id: item.id, data: { enable: item.enable } }).catch((error) => {
                item.enable = !item.enable;
                this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao habilitar/desabilitar."));
            });
        },

        newItem() {
            this.itemToEdit = {};
            this.$nextTick(() => {
                this.itemToEdit = null;
                this.openFormScreen();
            });
        },

        saveSuccess(res) {
            this.lockForm = false;
            this.$eventHub.$emit("msgSuccess", this.$http.getMessage(res) || this.$t("Dados salvo com sucesso."));
            this.changeDataList();
            if (this.keepOpenFormOnSave == false) {
                this.closeFormScreen();
            }
        },

        saveError(err) {
            this.lockForm = false;
            this.$eventHub.$emit("msgError", this.$http.getMessage(err) || this.$t("Erro ao salvar dados."));
        },

        deleteItem(item) {
            this.deleteItemID = item.id;
            this.deleteMessage = this.$t("Item selecionado para exclusão: " + item.id);
            this.deleteMessageItem = item[Object.keys(item)[1]];
            this.showDeleteModal = true;
        },

        deletedConfirm() {
            this.changeDataList();
            this.showDeleteModal = false;
        },

        saveForm(item, resolve) {
            this.lockForm = true;
            if (item.id) {
                this.$http
                    .put(this.endpoint, item.id, item)
                    .then((result) => {
                        resolve && resolve(result);
                        this.saveSuccess(result);
                    })
                    .catch((error) => this.saveError(error));
            } else {
                this.$http
                    .post(this.endpoint, item)
                    .then((result) => {
                        resolve && resolve(result);
                        this.saveSuccess(result);
                    })
                    .catch((error) => this.saveError(error));
            }
        },

        changeDataList() {
            if (this.forceReloadOnChange) {
                this.update();
            }
        },

        update(continuous = false) {
            if (!this.items) {
                this.$http
                    .get(`${this.endpoint}?skip=${this.options.itemsPerPage * (this.options.page - 1)}&limit=${this.options.itemsPerPage}${this.filterName ? "&search=" + this.filterName : ""}`, { continuous: continuous })
                    // .get(this.endpoint, { continuous: continuous })
                    .then((result) => {
                        this.itemsTable = result.items
                        this.itemsTotal = result.count
                    }).catch(() => (this.itemsTable = []));
            } else {
                this.$emit("needReload");
            }
        },

        updateLoop() {
            if (this.showForm == false) this.update(true);
        },
    },

    mounted() {
        if (this.updateTime > 0) this.timer = setInterval(this.updateLoop, this.updateTime * 1000);
    },

    created() {
        this.aditionalColumns.forEach((column) => {
            this.headers.splice(1, 0, column);
        });

        if (this.hideIdColumn) {
            this.headers.shift();
        }
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>